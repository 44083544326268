import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/me/aaa/github/personalsite/src/components/post_template.js";
import ImageSource from "src/components/image";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`gojs`}</h1>
    <p>{`Source code at `}<a parentName="p" {...{
        "href": "https://github.com/dashed/gojs"
      }}>{`github.com/dashed/gojs`}</a></p>
    <p>{`Demo at `}<a parentName="p" {...{
        "href": "https://dashed.github.io/gojs"
      }}>{`dashed.github.io/gojs`}</a>{`.`}</p>
    <p>{`A rules engine for a board game called `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Go_(game)"
      }}>{`Go`}</a>{`.`}</p>
    <p>{`Developed with `}<a parentName="p" {...{
        "href": "https://coffeescript.org/"
      }}>{`CoffeeScript`}</a>{`.`}</p>
    <br />
    <br />
    <hr />
    <br />
    <div style={{
      "position": "relative",
      "paddingBottom": "70.80%"
    }}>
  <iframe src="https://gfycat.com/ifr/SlightBreakableHerald?hd=1" frameBorder="0" scrolling="no" width="100%" height="100%" style={{
        "position": "absolute",
        "top": "0",
        "left": "0"
      }} allowFullScreen />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      